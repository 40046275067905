<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-container>
        <v-row justify="center" align="center" class="mb-4">
          <v-col align="center">
            <h1 class="sgrey--text text--darken-2">Contact us</h1>
          </v-col>
        </v-row>
        <v-row v-if="!sent" justify="center" class="my-0 py-0">
          <v-text-field
            label="Name"
            filled
            rounded
            dense
            hide-details="auto"
            style="max-width: 330px"
            v-model="name"
          ></v-text-field>
        </v-row>
        <v-row v-if="!sent" justify="center" class="my-3 py-0">
          <v-text-field
            label="Email"
            filled
            rounded
            dense
            hide-details="auto"
            style="max-width: 330px"
            v-model="email"
          ></v-text-field>
        </v-row>
        <v-row v-if="!sent" justify="center" class="mt-0 mb-4 py-0">
          <v-textarea
            filled
            auto-grow
            label="Your message"
            rows="4"
            hide-details="auto"
            row-height="30"
            shaped
            style="max-width: 330px"
            v-model="message"
          ></v-textarea>
        </v-row>
        <v-row v-if="!sent" justify="center" class="my-3 py-0">
          <v-btn depressed rounded color="grey lighten-2" @click="messageSent" :disabled="!validate()"
            >Send</v-btn
          >
        </v-row>
        <v-row v-if="sent" justify="center">
          <v-col align="center">
            <div
              class="text-overline grey--text text--darken-2"
              style="max-width: 450px"
            >
              Thank you for your message!
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import { sendEmail } from "@/services/Email";
export default {
  name: "Contact",
  components: {},
  data: () => ({
    valid: false,
    sent: false,
    name: "",
    email: "",
    message: ""
  }),
  methods: {
    messageSent() {
      sendEmail(this.name, this.email, this.message).then(() => {
        this.sent = true;
      })
    },
    validate() {
      let emailOK = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email)
      let nameOK = this.name.length > 2
      let messageOK = this.message.length > 10
      return nameOK && emailOK && messageOK
    },
  },
  created() {},
};
</script>

<style>
.opacityanimated {
  -webkit-animation-name: opacity;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-name: opacity;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes opacity {
  0%,
  25%,
  75%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}
@-webkit-keyframes opacity {
  0%,
  25%,
  75%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}
</style>