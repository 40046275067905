const axios = require("axios");

export function addEmail(email) {
    if(! /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        return
    }
    return axios.get(process.env.VUE_APP_BACKEND_PATH + '/addNewEmail', {
        headers: {},
        params: {
            email: email
        }
    });
}

export function sendEmail(name, email, message) {
    if(! /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        return
    }
    return axios.get(process.env.VUE_APP_BACKEND_PATH + '/sendMessage', {
        headers: {},
        params: {
            name: name,
            email: email,
            message: message
        }
    });
}